<!--
File: MaintenancePlan_all.vue
Description: form for showing all the treatment works populated from the HDM file.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-10">
          <BudgetYearsDropdown :label="$t('budget.select_year')" v-model="selectedYear" :initial_value="selectedYear"
            @input="onYearChange" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RegionsDropdown :label="$t('label.select_region')" v-model="selectedRegion" :initial_value="0"
            @input="onRegionChange" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <label for="currency">{{ $t('budget.select_currency') }}</label>
            <md-select v-model="currency" name="currency" id="currency">
              <md-option value="KGS">KGS</md-option>
              <md-option value="USD">USD</md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <md-input type="search" class="mb-3" clearable style="width: 200px" :placeholder="$t('label.search_records')"
              v-model="searchQuery"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="print"> {{ $t('buttons.print') }} </md-button>
          <md-button class="md-success" @click="onClose"> {{ $t('buttons.close') }}
          </md-button>
        </div>
      </div>
    </md-card-header>
    <md-card-content>
      <md-progress-spinner v-if="showSpinner == true" class="md-progress-spinner" :md-diameter="70"
        md-mode="indeterminate" />
      <md-table :value="queriedData" class="paginated-table table-striped table-hover" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell v-if="!selectedYear" style="width:5%" :md-label="$t('budget.year')" md-sort-by="year"> {{
            item.year }}
          </md-table-cell>
          <md-table-cell v-if="!selectedRegion" style="width:10%" :md-label="$t('road_network.region')"
            md-sort-by="region"> {{ item.region }}
          </md-table-cell>
          <md-table-cell style="width:10%" :md-label="$t('road_network.road_class')" md-sort-by="road_class"> {{
            item.road_class }}
          </md-table-cell>
          <md-table-cell style="width:20%" :md-label="$t('road_network.section')" md-sort-by="section"> {{ item.section }}
          </md-table-cell>
          <md-table-cell style="width:5%" :md-label="$t('road_network.length')" :md-numeric="true" md-sort-by="length"> {{
            item.length | numFormat }}
          </md-table-cell>
          <!--<md-table-cell md-label="AADT" :md-numeric="true"> {{ item.AADT }} </md-table-cell> -->
          <md-table-cell style="width:10%" :md-label="$t('condition.surface_type_desc')" md-sort-by="surface"> {{
            item.surface }}
          </md-table-cell>
          <md-table-cell style="width:10%" :md-label="$t('treatment.treatment_description')"
            md-sort-by="work_description"> {{
              item.work_description }} </md-table-cell>
          <md-table-cell style="width:10%" md-label="NPV_CAP" :md-numeric="true" md-sort-by="npv_cap">
            {{ item.npv_cap | numFormat }}
          </md-table-cell>
          <md-table-cell style="width:10%" :md-label="$t('budget.amount') + ', ' + $t('budget.mln') + currency"
            :md-numeric=true md-sort-by="financial_mln_kgs">
            {{ currency === "KGS" ? item.financial_mln_kgs : (item.financial_mln_kgs / exchange_rate) | numFormat }}
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>
    <md-card-actions class="md-card-actions" md-alignment="space-between">
      <div class>
        <p class="card-category">
          {{
            $t('label.showing_from_to_of_entries', {
              from: to > 0 ? from + 1 : 0,
              to: to,
              total
            })
          }}
        </p>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="pages">{{ $t('label.per_page') }}</label>
          <md-select v-model="pagination.perPage" name="pages">
            <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
        :per-page="pagination.perPage" :total="total"></pagination>
    </md-card-actions>
  </md-card>
</template>
<script>
  import Fuse from 'fuse.js'
  import { Pagination } from '@/components'
  import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
  import BudgetYearsDropdown from '../Dropdowns/BudgetYearsDropdown.vue'
  import { numFormat } from "@/store/refdata/format_helpers"

  export default {
    name: 'all-workprogrammes-list',
    data() {
      return {
        exchange_rate: 89,
        selectedYear: null,
        currency: "KGS",
        selectedRegion: null,
        regionDescr: null,
        showSpinner: false,
        pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [10, 25, 50],
          total: 0
        },
        currentSort: 'Year',
        currentSortOrder: 'asc',

        searchQuery: '',
        propsToSearch: ['section', 'work_description'],
        searchedData: [],
        fuseSearch: null,
      }
    },

    components: {
      Pagination,
      RegionsDropdown,
      BudgetYearsDropdown
    },

    created() {
      this.showSpinner = true
      this.selectedYear = new Date(Date.now()).getFullYear() + 1
      this.$store.dispatch('LOAD_ALL_WP_LIST').then(() => {
        // Fuse search initialization.
        this.fuseSearch = new Fuse(this.tableData, { keys: this.propsToSearch, threshold: 0.3 })
        this.showSpinner = false
      })
    },

    methods: {
      onClose() {
        history.back()
        history.replaceState({}, "", "/work_plan/budget")
       },
      onRegionChange(region_id, regionDescr) {
        this.regionDescr = regionDescr
      },
      print() {
        this.showSpinner = true
        let prtHtml = "<h4 style='text-align:center'>" + this.$t('label.reports_title') + "</h4>"
        prtHtml += "<h4 style='text-align:center'>" + this.$t('route.budget_show_all')

        let titleFilters = ''
        let tableHeaders = '<tr>'
        if (this.selectedYear) {
          titleFilters += this.$t('label.as_on') + this.selectedYear + this.$t('budget.year')
        } else {
          tableHeaders += `<th>${this.$t('budget.year')}</th>`
        }
        if (this.selectedRegion) {
          if (titleFilters !== '') titleFilters += ', &nbsp;'
          titleFilters += this.$t('label.from_region', { name: this.regionDescr })
        } else {
          tableHeaders += `<th>${this.$t('road_network.region')}</th>`
        }
        if (titleFilters !== '') prtHtml += '&nbsp;(' + titleFilters + ')'
        prtHtml += '</h4>'
        tableHeaders += `<th>${this.$t('road_network.road_class')}</th>
          <th>${this.$t('road_network.section')}</th>
          <th>${this.$t('road_network.length')}</th>
          <th>${this.$t('condition.surface_type_desc')}</th>
          <th>${this.$t('treatment.treatment_description')}</th>
          <th>${this.$t('NPV_CAP')}</th>
          <th>${this.$t('budget.amount') + ', ' + this.$t('budget.mln') + this.currency}</th>
        </tr>`

        let tableRows = ''
        this.tableData.forEach(item => {
          tableRows += '<tr>'
          if (!this.selectedYear) tableRows += `<td>${item.year}</td>`
          if (!this.selectedRegion) tableRows += `<td>${item.region}</td>`
          tableRows += `<td>${item.road_class}</td>
            <td>${item.section}</td>
            <td class='numCell'>${numFormat(item.length, 3)}</td>
            <td>${item.surface}</td>
            <td>${item.work_description}</td>
            <td class='numCell'>${numFormat(item.npv_cap, 3)}</td>
            <td class='numCell'>${numFormat(this.currency === "KGS" ? item.financial_mln_kgs 
              : (item.financial_mln_kgs / this.exchange_rate), 3)}</td>
          </tr>`
        })

        const PrintWin = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        PrintWin.document.write(`<!DOCTYPE html>
        <html>
          <head>
            <style>
              body { font-family: Arial, sans-serif; }
              table { width: 100%; border-collapse: collapse; margin: 20px 0; font-size: 12px; }
              th, td { border: 1px solid black; padding: 8px; }
              .numCell { text-align: right; }
            </style>
          </head>
          <body>${prtHtml}
             <table>
                <thead>${tableHeaders}</thead>
                <tbody>${tableRows}</tbody>
            </table>
          </body>
        </html>`
        )

        //setTimeout(() => { // Timeout is needed to finish loading
        PrintWin.document.close();
        this.showSpinner = false
        PrintWin.focus();
        PrintWin.print();
        PrintWin.close();
        //}, 500);
      },

      customSort(value) {
        return value.sort((a, b) => {
          const sortBy = this.currentSort
          const res = (!a[sortBy]) ? -1 : (!b[sortBy]) ? 1 :
            a[sortBy].toString().localeCompare(b[sortBy].toString(), undefined, { numeric: true })
          return (this.currentSortOrder === 'asc') ? res : -res
        })
      },
    },

    computed: {
      wpList() {
        return this.$store.state.WorkProgrammes.all_list
      },
      years() {
        return this.$store.state.WorkProgrammes.years
      },
      queriedData() {
        const result = !this.searchQuery ? this.tableData : this.searchedData //? this.searchedData : []
        return result.slice(this.from, this.to)
      },
      to() {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) highBound = this.total
        return highBound
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total() {
        return this.searchQuery ? this.searchedData.length : this.tableData.length
      },
      tableData() {
        let result = !this.selectedYear ? this.wpList : this.wpList.filter((item) => {
          return item.year == this.selectedYear
        })

        if (this.selectedRegion) {
          result = result.filter((item) => { return item.region_id == this.selectedRegion })
        }

        return result
      }
    },

    watch: {
      searchQuery(value) {
        this.searchedData = (value !== '') ? this.fuseSearch.search(this.searchQuery) : this.tableData
      }
    }
  }
</script>
<style lang="scss" scoped>
.filter-select {
  display: flex;

  position: relative;
  top: 20px;
  margin-left: auto;
  justify-content: flex-end;
  align-items: center;

  icon {
    margin-right: 10px;
  }

  .filter-select-dropdown {
    width: 200px;
    margin-left: 10px;
  }
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card {
  margin: 0px 0;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>